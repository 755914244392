import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19153d24 = () => interopDefault(import('..\\pages\\admin.vue' /* webpackChunkName: "pages_admin" */))
const _66c4f909 = () => interopDefault(import('..\\pages\\contato.vue' /* webpackChunkName: "pages_contato" */))
const _6cd685e8 = () => interopDefault(import('..\\pages\\contran.vue' /* webpackChunkName: "pages_contran" */))
const _81656660 = () => interopDefault(import('..\\pages\\em-breve.vue' /* webpackChunkName: "pages_em-breve" */))
const _5c3e6830 = () => interopDefault(import('..\\pages\\empresa.vue' /* webpackChunkName: "pages_empresa" */))
const _22334dc0 = () => interopDefault(import('..\\pages\\page.vue' /* webpackChunkName: "pages_page" */))
const _7cc5ae2f = () => interopDefault(import('..\\pages\\parceiros.vue' /* webpackChunkName: "pages_parceiros" */))
const _6b25b878 = () => interopDefault(import('..\\pages\\producao.vue' /* webpackChunkName: "pages_producao" */))
const _65d349cb = () => interopDefault(import('..\\pages\\produtos.vue' /* webpackChunkName: "pages_produtos" */))
const _2b96b342 = () => interopDefault(import('..\\pages\\socio-ambiental.vue' /* webpackChunkName: "pages_socio-ambiental" */))
const _7bf3e51e = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _19153d24,
    name: "admin"
  }, {
    path: "/contato",
    component: _66c4f909,
    name: "contato"
  }, {
    path: "/contran",
    component: _6cd685e8,
    name: "contran"
  }, {
    path: "/em-breve",
    component: _81656660,
    name: "em-breve"
  }, {
    path: "/empresa",
    component: _5c3e6830,
    name: "empresa"
  }, {
    path: "/page",
    component: _22334dc0,
    name: "page"
  }, {
    path: "/parceiros",
    component: _7cc5ae2f,
    name: "parceiros"
  }, {
    path: "/producao",
    component: _6b25b878,
    name: "producao"
  }, {
    path: "/produtos",
    component: _65d349cb,
    name: "produtos"
  }, {
    path: "/socio-ambiental",
    component: _2b96b342,
    name: "socio-ambiental"
  }, {
    path: "/",
    component: _7bf3e51e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
