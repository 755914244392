<template>
  <div class="main-container">
    <section class="fullscreen">
      <div class="container v-align-transform">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-1">
            <div class="text-center">
              <i class="ti-receipt icon icon-lg mb24 mb-xs-0" />
              <h1 class="large">
                ERRO 404
              </h1>
              <p>A página que você procura não foi encontrada</p>
              <nuxt-link class="btn" to="/">
                Voltar para a home
              </nuxt-link>
              <nuxt-link class="btn" to="/contato">
                Entrar em contato
              </nuxt-link>
            </div>
          </div>
        </div>
        <!--end of row-->
        <div class="embelish-icons">
          <i class="ti-help-alt" />
          <i class="ti-cross" />
          <i class="ti-support" />
          <i class="ti-announcement" />
          <i class="ti-signal" />
          <i class="ti-pulse" />
          <i class="ti-marker" />
          <i class="ti-pulse" />
          <i class="ti-alert" />
          <i class="ti-help-alt" />
          <i class="ti-alert" />
          <i class="ti-pulse" />
        </div>
      </div>
      <!--end of container-->
    </section>
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from '~/components/MainFooter.vue'

export default { // you can set a custom layout for the error page
  components: {
    MainFooter
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  }
}
</script>
