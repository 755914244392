import Vuex from 'vuex'
import axios from 'axios'

import * as pages from './pages'
import * as produtos from './produtos'

const createStore = () => {
  return new Vuex.Store({
    state,
    mutations,
    actions,
    modules: {
      pages,
      produtos
    }
  })
}

export const state = {
  loading: false
}

export const mutations = {
  fetchContatos (state, contatos) {
    state.contatos = contatos
  },
  switchLoader (state, payload = true) {
    state.loading = payload
  },
  loading (state, payload) {
    state.loading = payload
  }
}

export const actions = {
  fetchContatos (store) {
    // store.commit('switchLoader', false)
    axios.get(`http://br936.teste.website/~paludo86/wp-json/wp/v2/pages/?_embed=true&slug=contato`)
      .then(function (resp) {
        store.commit('fetchContatos', resp.data[0].acf)
      })
      .catch((error) => {
        if (error.response.data.data.status === 401) {
          window.location.href = '/em-breve'
        }
      })
  }
}

export default createStore
