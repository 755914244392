import axios from 'axios'

export const state = function () {
  return {
    list: []
  }
}

export const mutations = {
  fetchItens (state, payload) {
    state.list = payload
  }
}

export const actions = {
  fetchItens ({ commit }) {
    commit('loading', true)
    commit('fetchItens', null) // clear
    axios.get(`http://br936.teste.website/~paludo86/wp-json/wp/v2/produtos/?_embed=true`)
      .then((res) => {
        commit('fetchItens', res.data)
        commit('loading', false)
      })
      .catch((error) => {
        if (error.response.data.data.status === 401) {
          window.location.href = '/em-breve'
        }
      })
  }
}
