import axios from 'axios'

export const state = function () {
  return {
    list: [],
    currentPage: {}
  }
}

export const mutations = {
  fetchPages (state, payload) {
    state.list = payload
  },
  fetchPage (state, payload) {
    state.currentPage = payload
  }
}

export const actions = {
  fetchPages (store) {
    // store.commit('switchLoader')
    store.commit('fetchPages', {}) // clear
    axios.get(`http://br936.teste.website/~paludo86/wp-json/wp/v2/pages/?_embed=true`)
      .then((res) => {
      // store.commit('switchLoader', false)
        store.commit('fetchPages', res.data)
      })
      .catch((error) => {
        if (error.response.data.data.status === 401) {
          window.location.href = '/em-breve'
        }
      })
  },
  fetchPage (store, slug) {
    // store.commit('switchLoader')
    store.commit('fetchPage', {}) // clear
    axios.get(`http://br936.teste.website/~paludo86/wp-json/wp/v2/pages/?_embed=true&slug=${encodeURI(slug)}`)
      .then((res) => {
      // store.commit('switchLoader', false)
        if (res.data[0] === undefined) {
          window.location.href = '/error'
          return
        }
        store.commit('fetchPage', res.data[0])
      })
      .catch((error) => {
        if (error.response.data.data.status === 401) {
          window.location.href = '/em-breve'
        }
      })
  }
}
