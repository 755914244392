<template>
  <div :class="{'loading': loading}" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState([
    'loading'
  ])
}
</script>

<style>
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
